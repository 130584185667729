<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4 class="mb-3">Purchase Return Details</h4>
            <div class="btn-group">
                <btn @click="$router.push('/finance/return/' + id + '/history/')" size="sm" icon="fa fa-history"
                     text="History"/>
                <btn @click="$router.push('/finance/return/list/')" size="sm" text="Back"/>
                <btn v-if="returnItems.status!=='Payment Completed'" size="sm"
                     @click="$refs.paymentPage.submitClicked()" text="Submit"/>
            </div>
        </div>
        <div class="card bs-4 p-0 pb-3">
            <h5 class="p-3 bb-1">Purchase Info</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Purchase ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.id }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Vendor</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.vendor }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.date }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.status }}</div>
                <div class="col-1"/>
                <div class="col-1 label">Remarks</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ entry.remarks }}</div>
                <div class="col-1"/>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" ref="table" title="Items"
                                 :fields="fields" :data="entry.items">
                <template #no="rowData">
                    <span>{{ getSlNo(rowData.rowData, entry.items) }}</span>
                </template>
            </custom-simple-table>
        </div>
        <div class="card bs-4 p-0 pb-3">
            <h5 class="p-3 bb-1">Purchase Return Info</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Return ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ returnItems.id }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Vendor</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ returnItems.vendor }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ returnItems.date }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ returnItems.status }}</div>
                <div class="col-1"/>
                <div class="col-1 label">Created By</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ returnItems.created_by }}</div>
                <div class="col-1"/>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" ref="table" title="Items"
                                 :fields="fields1" :data="returnItems.items">
                <template #no="rowData">
                    <span>{{ getSlNo(rowData.rowData, returnItems.items) }}</span>
                </template>
            </custom-simple-table>
        </div>
        <payment-details-page
            v-if="returnItems.status!=='Payment Completed' && returnItems.status!=='FA Rejected'"
            ref="paymentPage" :payment="payments"
            :status="returnItems.status" :return_id="id"/>
        <payment-details-view v-if="returnItems.status==='Payment Completed' && returnItems.status!=='FA Rejected'"
                              :payment="payments"/>
        <attachments v-if="returnItems.status!=='FA Rejected'" :return_id="id" :status="returnItems.status"/>
    </div>
</template>

<script>

import urls from '../../../data/urls';
import axios from 'secure-axios';
import PaymentDetailsPage from '../PurchaseReturn/Components/PaymentDetails';
import PaymentDetailsView from '../PurchaseReturn/Components/PaymentDetailsView';
import Attachments from '../PurchaseReturn/Components/Attachments';

export default {
    name       : 'PurchaseReturnDetails',
    components : { Attachments, PaymentDetailsView, PaymentDetailsPage },
    data () {
        return {
            DataLoading : false,
            fields      : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Items'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                }
            ],
            fields1 : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Items'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                },
                {
                    name      : 'reason',
                    sortField : 'reason',
                    title     : 'Reason'
                }
            ],
            statusOption : [
                { label : 'Accepted', value : 'Accepted' },
                { label : 'Reject', value : 'Rejected' }
            ],
            id          : this.$route.params.id,
            status      : '',
            entry       : {},
            payments    : {},
            returnItems : {}
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.financeAdmin.purchaseReturn.details, { return_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.entry = json.purchase_entry;
                that.returnItems = json.purchase_return;
                that.payments = json.payments;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
