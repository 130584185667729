<template>
    <div>
        <div class="card mt-5 bs-4 p-0 pb-3">
            <h5 class="bb-1 p-3">Payment Details</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Amount</div>
                <div class="col-2 ">
                    <validated-input placeholder="Amount" name="Amount" type="number"
                                     v-model="model.amount" :rules="rules.amount"/>
                </div>
                <div class="col-1"/>

                <div class="col-1 label">Method</div>
                <div class="col-2 ">
                    <validated-vue-select :rules="{required:true}" name="Method" :options="paymentOptions"
                                          placeholder="Method"
                                          v-model="model.payment_mode"/>
                </div>
                <div class="col-1"/>

                <div class="col-2 p-0 label">Payment Status</div>
                <div class="col-2 p-0">
                    <validated-vue-select :rules="{required:true}" name="Payment Status" :options="returnStatusOptions"
                                          placeholder="Payment Status"
                                          v-model="model.payment_status"/>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">

                <div class="col-1 label">Reference</div>
                <div class="col-2 ">
                    <validated-input :rules="{required:true}" placeholder="Reference" name="Reference"
                                     v-model="model.reference"/>
                </div>
                <div class="col-1"/>

                <div v-if="payment.payment_date" class="col-1 label">Date</div>
                <div v-if="payment.payment_date" class="col-2 ">
                    <validated-input disabled placeholder="Date" name="Date" v-model="payment.payment_date"/>
                </div>
                <div v-if="payment.payment_date" class="col-1"/>
            </div>
        </div>
        <div v-if="status!=='FA Approved'" class="card bs-4 p-0">
            <h5 class="p-3 bb-1">Finance Admin Status</h5>
            <div class="row p-3">
                <div class="col-1 label">Status</div>
                <div class="col-2">
                    <validated-vue-select :rules="{required:true}" name="Status" placeholder="Status"
                                          :options="statusOptions"
                                          v-model="model.status"/>
                </div>
                <div class="col-1"/>
                <div class="col-1 label">Remarks</div>
                <div class="col-2 ">
                    <validated-input :rules="{required:true}" placeholder="Remarks" name="Remarks"
                                     v-model="model.remarks"/>
                </div>
                <div class="col-1"/>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'PaymentDetailsPage',
    props : {
        return_id : { type : String },
        payment   : { type : Object },
        status    : { type : String }
    },
    data () {
        return {
            data        : '',
            DataLoading : false,
            model       : {
                return_id      : '',
                remarks        : '',
                status         : '',
                amount         : '',
                payment_date   : '',
                payment_mode   : '',
                payment_status : '',
                reference      : ''
            },
            rules : {
                amount : {
                    required  : true,
                    min_value : 0
                }
            },
            returnStatusOptions : [
                { value : 'Pending', label : 'Pending' },
                { value : 'Partially Completed', label : 'Partially Completed' },
                { value : 'Completed', label : 'Completed' }
            ],
            statusOptions : [
                { value : 'Accepted', label : 'Accepted' },
                { value : 'Rejected', label : 'Rejected' }
            ],
            paymentOptions : [
                { value : 'Cash', label : 'Cash' },
                { value : 'Fund Transfer', label : 'Fund Transfer' },
                { value : 'UPI Payment', label : 'UPI Payment' },
                { value : 'Demand Draft', label : 'Demand Draft' },
                { value : 'NEFT', label : 'NEFT' },
                { value : 'RTGS', label : 'RTGS' },
                { value : 'Wallet Transfer', label : 'Wallet Transfer' }
            ]
        };
    },
    mounted () {
        this.setData();
    },
    methods : {
        setData () {
            this.model.return_id = this.return_id;
            if (this.payment) {
                this.model.remarks = this.payment.remarks;
                this.model.status = this.payment.status;
                this.model.amount = this.payment.amount;
                this.model.payment_date = this.payment.payment_date;
                this.model.payment_mode = this.payment.payment_mode;
                this.model.payment_status = this.payment.payment_status;
                this.model.reference = this.payment.reference;
            }
        },
        async submitClicked () {
            if (this.status === 'FA Approved') {
                this.model.status = 'Accepted';
            }
            this.model.return_id = this.return_id;
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.financeAdmin.purchaseReturn.submit, that.model);
            const json = response.data;
            if (json.error === false) {
                that.$notify('Submitted Successfully', 'Success', {
                    type : 'success'
                });
                that.$router.push('/finance/return/list/');
                that.loading = false;
            } else if (json.msg.status || json.msg.remarks || json.msg.amount || json.msg.payment_mode || json.msg.payment_status) {
                that.$notify('Complete the payment details to continue.', 'Message', {
                    type : 'info'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to submit the return entry.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
